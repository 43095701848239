<template>
	<div class="my-account va-row">
		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom">
					<h1>Minha Conta</h1>
				</div>
				<menu-tabs
					:items="[
						{ label: 'Dados Pessoais', slug: 'my-data', isActive: false },
						{ label: 'Meus Endereços', slug: 'adresses', isActive: true },
						{ label: 'Meus Cartões', slug: 'cards', isActive: false },
					]"
					@scrollTo="scrollParent"
					ref="tabs"
					class="container-ddh-menu"
				>
				</menu-tabs>
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row">
			<div class="flex md12 xs12 no-padding">
				<h4>Editar Endereço</h4>
			</div>

			<div class="flex md8 xs12 no-padding">
				<div class="ui-grid">
					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding fiscal-notes">
							<div class="body-spacing">
								<div class="va-row">
									<div class="error-label" v-if="errors.length > 0">
										{{ errors[0].message }}
									</div>

									<form @submit.prevent="saveAddress" class="va-row">
										<!-- {{isCreation}} -->

										<div class="flex xs12 md12 no-padding">
											<input-normal
												type="text"
												label="Identificação do Endereço"
												name="label"
												:required="'true'"
												:value="addressEdit.addressLabel"
												:disabled="false"
												@input="addressEdit.addressLabel = $event.target.value"
											/>
											<small>Ex: Meu Apartamento</small>

											<select-normal
												:items="[
													{ key: 'Apartamento', value: 'Apartamento' },
													{ key: 'Casa', value: 'Casa' },
													{ key: 'Comercial', value: 'Comercial' },
												]"
												:value="addressEdit.addressTypeName"
												label="Tipo de residência"
												name="typeName"
												@change="onChange($event)"
											/>

											<input-normal
												type="cep"
												label="CEP"
												name="cep"
												:required="'true'"
												v-mask="['#####-###']"
												:disabled="cepSearch"
												:value="addressEdit.zipPostalCode"
												@input="updateAndVerifyCep"
											/>

											<!-- <div class="address-info" v-if="!openAddressField && addressEdit.address1 !== ''">
                                {{addressEdit.address1}}<br>
                                {{addressEdit.district}}, {{addressEdit.city}} - {{addressEdit.stateId}}
                            </div> -->

											<div v-if="openAddressField">
												<select-normal
													:items="[
														{ value: 'Acre', key: 'AC' },
														{ value: 'Alagoas', key: 'AL' },
														{ value: 'Amapá', key: 'AP' },
														{ value: 'Amazonas', key: 'AM' },
														{ value: 'Bahia', key: 'BA' },
														{ value: 'Ceará', key: 'CE' },
														{ value: 'Distrito Federal', key: 'DF' },
														{ value: 'Espírito Santo', key: 'ES' },
														{ value: 'Goiás', key: 'GO' },
														{ value: 'Maranhão', key: 'MA' },
														{ value: 'Mato Grosso', key: 'MT' },
														{ value: 'Mato Grosso do Sul', key: 'MS' },
														{ value: 'Minas Gerais', key: 'MG' },
														{ value: 'Pará', key: 'PA' },
														{ value: 'Paraíba', key: 'PB' },
														{ value: 'Paraná', key: 'PR' },
														{ value: 'Pernambuco', key: 'PE' },
														{ value: 'Piauí', key: 'PI' },
														{ value: 'Rio de Janeiro', key: 'RJ' },
														{ value: 'Rio Grande do Norte', key: 'RN' },
														{ value: 'Rio Grande do Sul', key: 'RS' },
														{ value: 'Rondônia', key: 'RO' },
														{ value: 'Roraima', key: 'RR' },
														{ value: 'Santa Catarina', key: 'SC' },
														{ value: 'São Paulo', key: 'SP' },
														{ value: 'Sergipe', key: 'SE' },
														{ value: 'Tocantins', key: 'TO' },
													]"
													:value="addressEdit.stateId"
													label="Selecione um estado"
													name="typeName"
													@change="onChangeState($event)"
												/>

												<input-normal
													type="text"
													label="Endereço"
													name="address1"
													:value="addressEdit.address1"
													:disabled="false"
													@input="addressEdit.address1 = $event.target.value"
												/>
												<input-normal
													type="text"
													label="Bairro"
													name="district"
													:value="addressEdit.district"
													:disabled="false"
													@input="addressEdit.district = $event.target.value"
												/>
												<input-normal
													type="text"
													label="Cidade"
													name="city"
													:value="addressEdit.city"
													:disabled="false"
													@input="addressEdit.city = $event.target.value"
												/>
											</div>

											<div v-if="addressEdit.addressTypeName">
												<input-normal
													type="number"
													label="Número"
													name="number"
													:maxlength="5"
													:max="9999"
													:required="'true'"
													:disabled="false"
													:value="addressEdit.addressNumber"
													@input="
														addressEdit.addressNumber = $event.target.value
													"
												/>
												<input-normal
													type="text"
													label="Complemento (opcional)"
													name="complement"
													:value="addressEdit.address2"
													:disabled="false"
													@input="addressEdit.address2 = $event.target.value"
												/>
												<input-normal
													type="text"
													label="Informações de referência"
													name="reference"
													:required="'true'"
													:value="addressEdit.addressReference"
													:disabled="false"
													@input="
														addressEdit.addressReference = $event.target.value
													"
												/>
												<small
													>Nos informe lugares próximos para facilitar a sua
													entrega</small
												>
											</div>

											<!-- <div v-if="addressEdit.addressTypeName !== '' && addressEdit.address1 !== ''">
                            
                              <input-normal type="number" label="Número" name="number" :maxlength="5" :max="9999" :required="'true'" :disabled="false" :value="addressEdit.addressNumber" @input="addressEdit.addressNumber = $event.target.value" />
                              <input-normal type="text" label="Complemento (opcional)" name="complement" :value="addressEdit.address2" :disabled="false" @input="addressEdit.address2 = $event.target.value" />
                              <input-normal type="text" label="Informações de referência" name="reference" :required="'true'" :value="addressEdit.addressReference" :disabled="false" @input="addressEdit.addressReference = $event.target.value" />
                              <small>Nos informe lugares próximos para facilitar a sua entrega</small>
                            </div> -->
										</div>

										<div class="flex md12 no-padding">
											<h5>Informações do destinatário</h5>
										</div>

										<div class="flex xs12 md6 no-padding-left name-box">
											<input-normal
												type="text"
												label="Nome"
												name="name"
												:required="'true'"
												:disabled="false"
												:value="addressEdit.name"
												@input="addressEdit.name = $event.target.value"
											/>
											<small>Será impresso na etiqueta de envio</small>
										</div>
										<div class="flex xs12 md6 no-padding-right lastname-box">
											<input-normal
												type="text"
												label="Sobrenome"
												name="lastname"
												:required="'true'"
												:disabled="false"
												:value="addressEdit.lastname"
												@input="addressEdit.lastname = $event.target.value"
											/>
										</div>
										<div class="flex xs12 md12 no-padding">
											<input-normal
												type="text"
												label="Telefone"
												name="phone"
												:required="'true'"
												v-mask="['(##) ####-####', '(##) #####-####']"
												:disabled="false"
												:value="addressEdit.phoneNumber"
												@input="addressEdit.phoneNumber = $event.target.value"
											/>
										</div>

										<div class="flex md12 text-right">
											<button type="submit" class="btn btn-primary right">
												SALVAR EDIÇÃO
											</button>
											<button
												type="button"
												class="btn btn-primary right cancel"
												v-if="
													subscriptionSend.subscriptions.length === 0 &&
													isCreation !== true
												"
												v-on:click="deleteAddress()"
											>
												EXCLUIR ENDEREÇO
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="flex md3 xs12 subscription-box" v-if="subscriptionsData">
				<div class="panel newsletter">
					Deseja utilizar esse endereço no Clube?<br />

					<div
						v-for="(subscriptionItem, index) in subscriptionsData"
						:key="index"
					>
						<!-- {{subscriptionItem.shippingAddressId}} -->
						<!-- Profile ID: {{subscriptionItem.paymentProfileId}}<br>
                    Profile ID: {{subscriptionItem.shippingAddressId}} -->

						<RadioButton
							class="box-payment box-method"
							:value="false"
							:selected="getSelectedId(subscriptionItem.id)"
							@click="selectSubscription(subscriptionItem.id, index)"
						>
							<div>{{ subscriptionItem.characterName }}</div>
						</RadioButton>
					</div>

					<div v-if="subscriberEditing" class="editing">
						<button
							type="submit"
							class="btn btn-primary right"
							v-on:click="saveAddress()"
						>
							SALVAR EDIÇÃO
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	const axios = require("axios");

	export default {
		name: "address",
		directives: { mask },
		components: {
			// vuescroll
			// VueScrollTo
			// DataVisualisationTab,
		},

		mounted() {
			const self = this;

			axios
				.get(process.env.VUE_APP_ROOT_API + "/customer/subscriptions")
				.then((response) => {
					self.subscriptionsData = response.data.data.subscriptions;
					self.updateSubscriptionSend();

					if (self.address.zipPostalCode.length > 0) {
						self.isCreation = false;
					}
				})
				.catch((e) => {
					self.errors.push(e);
				});

			this.addressEdit = this.address;
			if (this.addressEdit.nameRecipient !== undefined) {
				this.addressEdit.name = this.addressEdit.nameRecipient
					.split(" ")
					.slice(0, -1)
					.join(" ");
				this.addressEdit.lastname = this.addressEdit.nameRecipient
					.split(" ")
					.slice(-1)
					.join(" ");
			}
		},

		props: ["address", "subscription"],

		data() {
			return {
				subscriptionsData: null,
				addressEdit: {
					addressLabel: "",
				},
				subscriberEditing: false,
				subscriptionSend: {
					subscriptions: [],
					address: this.addressEdit,
				},
				percentCall: 0,
				isCreation: true,

				openAddressField: false,

				cepSearch: false,
				// errors: []
			};
		},
		methods: {
			onChange(event) {
				this.addressEdit.addressTypeName = event.target.value;
				//this.address = event.target.value
			},

			onChangeState(event) {
				this.addressEdit.stateId = event.target.value;
				//this.address = event.target.value
			},

			updateSubscriptionSend() {
				this.subscriptionsData.forEach((element) => {
					if (element.shippingAddressId === this.address.id) {
						let subscriptionId = { id: element.id };
						this.subscriptionSend.subscriptions.push(subscriptionId);
					}
				});
			},

			created: function () {
				const self = this;

				if (
					this.addressEdit.zipPostalCode.length === 9 &&
					self.cepSearch === false
				) {
					self.cepSearch = true;

					axios
						.get(
							`https://api.pagar.me/1/zipcodes/${this.addressEdit.zipPostalCode}`
						)
						.then((res) => {
							this.addressEdit.stateId = res.data.state;
							this.addressEdit.city = res.data.city;
							this.addressEdit.district = res.data.neighborhood;
							this.addressEdit.address1 = res.data.street;

							//if(res.data.street === undefined){
							this.openAddressField = true;
							//}

							self.cepSearch = false;
						})
						.catch((error) => {
							this.openAddressField = true;
							self.cepSearch = true;

							//console.log(error)
						});
				}
			},

			updateAndVerifyCep({ e, target }) {
				this.addressEdit.zipPostalCode = target.value;
				this.created();
			},

			selectSubscription(id, index) {
				this.subscriberEditing = true;
				let subscriptionId = { id: id };

				// alert(this.subscriptionsData[index].shippingAddressId);
				// this.subscriptionsData[index].shippingAddressId = this.address.id

				var index = this.subscriptionSend.subscriptions.indexOf(
					this.subscriptionSend.subscriptions.find((c) => {
						return c.id === id;
					})
				);
				if (index > -1) {
					this.subscriptionSend.subscriptions.splice(index, 1);
				} else {
					this.subscriptionSend.subscriptions.push(subscriptionId);
				}
			},

			getSelectedId(id) {
				let response = false;

				let objectId = this.subscriptionSend.subscriptions.find((c) => {
					return c.id === id;
				});
				if (objectId) {
					if (objectId.id === id) {
						response = true;
					}
				}

				// if(id == this.address.id){
				//   response = true
				// }

				return response;
			},

			deleteAddress() {
				const self = this;

				axios
					.delete(
						process.env.VUE_APP_ROOT_API +
							"/customer/address/" +
							this.address.id
					)
					.then((response) => {
						self.$router.push({ name: "adresses" });
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},

			saveAddress() {
				const self = this;

				let config = {
					onDownloadProgress: (progressEvent) => {
						let percentCompleted = Math.floor(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						this.percentCall = percentCompleted;
						// do whatever you like with the percentage complete
						// maybe dispatch an action that will update a progress bar or something
					},
				};

				this.$validator.validateAll().then((result) => {
					if (result) {
						// eslint-disable-next-line

						// this.addressEdit.nameRecipient = this.addressEdit.name + " " + this.addressEdit.lastname
						this.addressEdit.nameRecipient =
							this.addressEdit.name + " " + this.addressEdit.lastname;
						this.subscriptionSend.address = this.addressEdit;

						if (this.isCreation) {
							axios
								.post(
									process.env.VUE_APP_ROOT_API + "/customer/address",
									this.subscriptionSend,
									config
								)
								.then(function (response) {
									if (response.data.success !== false) {
										self.$router.push({ name: "adresses" });
										self.percentCall = 0;
									} else {
										self.errors.push(response.data.errors[0]);
									}
								})
								.catch(function (error) {
									console.log(error);
								});
						} else {
							axios
								.put(
									process.env.VUE_APP_ROOT_API + "/customer/address",
									this.subscriptionSend,
									config
								)
								.then(function (response) {
									if (response.data.success !== false) {
										self.$router.push({ name: "adresses" });
										self.percentCall = 0;
									} else {
										self.errors.push(response.data.errors[0]);
									}
								})
								.catch(function (error) {
									console.log(error);
								});
						}
					}
				});
			},

			showSubscriberEditing() {
				this.subscriberEditing = false;
			},
			hideSubscriberEditing() {
				this.subscriberEditing = false;
			},

			scrollParent(slug) {
				this.$router.push({ name: slug });
			},
		},
	};
</script>
<style lang="scss" scoped>
	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	.fiscal-notes ::v-deep .form-group:last-child {
		margin-bottom: 10px !important;
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	// Default of page

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 30px 30px 10px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
</style>
